import React from 'react'
import ReactDOM from 'react-dom'
import {
    defineCustomElements as defineAudiExclusiveAppManager,
} from '@oneaudi/avp-audi-exclusive-web-component/loader'

import FeatureApp from './feature-app'

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL || '/'

    defineAudiExclusiveAppManager(window, {
        resourcesUrl: `${REACT_APP_BASE_URL}ae/`,
    })

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<FeatureApp />, document.getElementById('root'))
