import React, { FunctionComponent } from 'react'
import { createFeatureHub } from '@feature-hub/core'
import { loadAmdModule } from '@feature-hub/module-loader-amd'
import { FeatureHubContextProvider, FeatureAppLoader } from '@feature-hub/react'
import defineMiwiFeatureService from '@audi/miwi-feature-service'
import externals from './externals'
import config from '../config'

const { featureAppManager } = createFeatureHub('dasburo:dcc-app-integrator', {
    moduleLoader: loadAmdModule,
    providedExternals: externals,
    featureServiceDefinitions: [
        defineMiwiFeatureService(),
    ],
})

const FeatureApp: FunctionComponent = () => (
    <FeatureHubContextProvider value={{ featureAppManager }}>
        <FeatureAppLoader
            featureAppId="dasburo:dcc"
            src={`dccFeatureApp-${config.APP_VERSION}.umd.js`}
        />
    </FeatureHubContextProvider>
)

export default FeatureApp
